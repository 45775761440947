#contact{
  .info{
    margin-bottom: 1em;
    i{
      font-size: 20px;
      color: #ffb03b;
      height: 44px;
      background: #fff6e8;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
    }
  }
}