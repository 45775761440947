section {
  padding: 60px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
}
.section-title h2 span {
  color: #ffb03b;
}
.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}
@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}