#product {
  //min-height: 100vh;

  .img-title {
    background: url("/img/bg/about.jpg") no-repeat right;
    background-size: cover;
  }

  .carousel-item, .fz_p1, .fz_p2, .fz_p3, .fz_p4, .fz_p5 {
    height: calc(100vh - 70px);
  }

  .fz_p1, .fz_p2, .fz_p3, .fz_p4 {
    background-color: #301309;
  }

  .fz_p5 {
    background-color: #DED9D1;
  }

  .fz_p1, .fz_p2, .fz_p3, .fz_p4 {
    .content {
      color: #fcfcf4;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    text-align: right;
  }

  .content {
    font-size: 1rem;

    h2 {
      letter-spacing: 5px;
      font-size: 1.2em;
    }

    p {
      letter-spacing: .1em;
      line-height: 2em;
      font-weight: lighter;
      font-size: .75em;
    }
  }
}

//#carouselProduct {
//  .carousel-item .row {
//    min-height: 90vh;
//  }
//}