#gallery{
  .gallery-item {
    text-align: center;
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    display: block;
    position: relative;
    img {
      transition: all ease-in-out 0.4s;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
    .glightbox-desc{
      position: absolute;
      bottom: 1px;
      width: 100%;
      //background-color: rgba(255,176,59,0.5);
      background-color: rgba(0,0,0,0.5);

      display: block !important;
      p{
        color: #FFFFFF;
        text-shadow: 2px 2px 2px #000000;
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }
}

#glightbox-slider{
  .gdesc-inner{
    p{
      text-align: center;
      font-weight: bold;
    }
  }
}