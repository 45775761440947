//Import variables
@import "variables";

//Import Bootstrap
//@import "bootstrap";

//@import "custom/back_to_top";

@import "cmn/common";
@import "cmn/header";
@import "cmn/buy_btn";
@import "cmn/nav_d";
@import "cmn/nav_m";
@import "cmn/carousel";
@import "cmn/section";
@import "cmn/product";
@import "cmn/gallery";
@import "cmn/contact";

@import "cmn/footer";


#product {
  .btn-prod {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 50px;
    -webkit-transition: .5s;
    transition: .5s;
    line-height: 1;
    margin: 0 10px;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
    color: #ffb03b;
    border: 2px solid #ffb03b;

    &:hover {
      background: #ffb03b;
      color: #fff;
    }
  }
}