body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
}

a {
  text-decoration: none;
  color: #ffb03b;
}

a:hover {
  color: #ffc56e;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', cursive;
}