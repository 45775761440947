.book-a-table-btn {
  background: #ffb03b;
  color: #fff;
  border-radius: 50px;
  margin: 0 0 0 20px;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}
.book-a-table-btn:hover {
  background: #ffa012;
  color: #fff;
}
@media (max-width: 992px) {
  .book-a-table-btn {
    margin: 0 15px 0 0;
    padding: 8px 20px;
    letter-spacing: 1px;
  }
}